import React from 'react'
import { ThemeProvider } from 'styled-components'
import './src/global.css'

import theme from './src/theme'

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <div className="overflow">{element}</div>
    </ThemeProvider>
  )
}
