// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-faqs-js": () => import("../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-features-js": () => import("../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mortgage-js": () => import("../src/pages/mortgage.js" /* webpackChunkName: "component---src-pages-mortgage-js" */),
  "component---src-pages-pricing-js": () => import("../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */)
}

